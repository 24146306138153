<template>
  <div class="w_auto">
      <div class="allist mt15 mb50">
          <div class="shadow radius5 bgwhite pad10">
              <h2>{{detailList.noticeTheme}}</h2>
              <div class="newscont pad20">
                  <p v-html="detailList.noticeDesc"></p>
              </div>
              <div class="pd20 cor4">
                  <span><i class="el-icon-time"></i><b style="font-weight:400">发布时间：</b> {{detailList.createTime}} </span>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { dateFormat } from '@/utils/time.js'
export default {
    data(){
        return{
            token: localStorage.getItem("pc_token"),
            detailList:[],
        }
    },
    mounted(){
        this.findChannelNoticeById()
    },
    methods:{
        findChannelNoticeById(){
            this.$api.findChannelNoticeById({
                token:this.token,
                noticeId:this.$route.query.id
            }).then(res => {
                if(res.code == 10000){
                    this.detailList = res.data.channelNotice
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.mt15 {
    margin-top: 15px;
}
.mb50{
    margin-bottom: 50px;
}
.pad10 {
    padding: 10px 5px;
}
.shadow {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.radius5 {
    border-radius: 5px;
}
.bgwhite {
    display: inline-block;
    background-color: #fff;
    width: 100%;
}
.allist h2 {
    padding: 40px 0 20px;
    text-align: center;
    font-weight: 400;
    font-size: 21px;
}
.release-info {
    padding: 20px;
    text-align: right;
}
.borderbot {
    border-bottom: 2px solid #f7f7f7;
}
.release-info span {
    color: #666;
    font-size: 14px;
}
.release-info span b {
    color: #000;
    font-weight: 400; 
}
.newscont {
    text-indent: 20px;
    line-height: 30px;
    font-size: 14px;
}
.newscont p {
    margin-bottom: 15px;
    color: #333;
}
.pad20 {
    padding: 20px;
}
.cor4{
    padding: 20px;
    color: #444;
    text-align: right;
    font-size: 14px;
}
</style>